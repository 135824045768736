import { collection, doc } from "firebase/firestore"
import { Employee } from "./Employee"
import { FirestoreClass } from "./FirestoreClass"
import { uuid } from "vue-uuid"

export enum CampaignRuleOperator {
    isEqual = "==",
    isGreaterThan = ">",
    isGreaterOrEqualThan = ">=",
    isLowerThan = "<",
    isLowerOrEqualThan = "<=",
    assigned = "assigned"
}

export class CampaignRule {
    productid: string = ""
    operator: CampaignRuleOperator = CampaignRuleOperator.isEqual
    fieldid: string = ""
    value: string = ""
    uniqueId: string


    constructor(data: Record<string, unknown> | null = null) {

        this.productid = data?.productid as string ?? ""
        this.operator = data?.operator as CampaignRuleOperator ?? CampaignRuleOperator.isEqual
        this.fieldid = data?.fieldid as string ?? ""
        this.value = data?.value as string ?? ""
        this.uniqueId = data?.uniqueId as string ?? uuid.v4()

    }

    toData() {
        const data: Record<string, unknown> = {
            productid: this.productid,
            operator: this.operator,
            fieldid: this.fieldid,
            value: this.value,
            uniqueId: this.uniqueId
        }
        return data
    }
}

export class Campaign extends FirestoreClass {
    private _employee: Employee
    name: string = ""
    rules: CampaignRule[] = []
    isDefault: boolean = false
   
    constructor(_employee: Employee, id: string = "") {
        super(id)
        this._employee = _employee
    }

    get employee() {
        return this._employee
    }

    override fromData(data: Record<string, unknown>): void {
        if (data) {
            this.name = data.name as string ?? ""
            this.isDefault = data.isDefault as boolean ?? false
            this.rules = []
            
            if (data.rules) {
                this.rules = []
                for (const fieldData of data.rules as (Record<string, unknown> | null)[]) {
                    const tempField = new CampaignRule(fieldData)
                    this.rules.push(tempField)
                }
            }
        }
    }
    override toData() {
        const data: Record<string, unknown> = {}
        data.name = this.name
        data.isDefault = this.isDefault
      
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rules: any[]  = []
        for (const rule of this.rules) {
            rules.push(rule.toData())
        }
        data.rules = rules

        return data
    }

    get ref() {
        return doc(this.collectionRef, this.id)
    }

    get collectionRef() {
        return collection(this._employee.ref, "campaigns")
    }
}
