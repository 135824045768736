import { FirestoreClass } from "./FirestoreClass"
import { Customer } from "./Customer"
import { collection, doc } from "firebase/firestore"


export class CategoryHeadlineItemFile {
    category: string = ""
    headline: string = ""
    guid: string = ""
    name: string = ""
    title: string = ""
    declineMessage: string = ""
    declined: boolean = false


    constructor(data: Record<string, unknown> | null = null) {
        this.category = data?.category as string || ""
        this.headline = data?.headline as string || ""
        this.guid = data?.guid as string || ""
        this.name = data?.name as string || ""
        this.title = data?.title as string || ""
        this.declineMessage = data?.declineMessage as string || ""
        this.declined = data?.declined as boolean || false
    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title,
            name: this.name,
            guid: this.guid,
            headline: this.headline,
            category: this.category,
            declineMessage: this.declineMessage,
            declined: this.declined
        }

        return data
    }
}
export class CategoryHeadlineItemSubQuestion {
    type: string = ""
    title: string = ""
    answer: string = ""
    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.type = data.type as string || "",
            this.title = data.title as string || "",
            this.answer = data.answer as string || ""
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            type: this.type as string || "",
            title: this.title as string || "",
            answer: this.answer as string || ""
        }
        return data
    }
}
export class CategoryHeadlineItemAnswer {
    title: string = ""
    subquestion: CategoryHeadlineItemSubQuestion = new CategoryHeadlineItemSubQuestion({})

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.title = data.title as string || "",
            this.subquestion = new CategoryHeadlineItemSubQuestion(data.subquestion as Record<string, unknown>)
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title as string || "",
            subquestion: this.subquestion.toData()
        }
        return data
    }
}

export class CategoryHeadlineItemDynTemplateField {
    default: string = ""
    field: string = ""
    title: string = ""
    type: string = ""
    ifPartner: boolean = false
    multiple: boolean = false
    objToAdd: Record<string, unknown> = {}

    constructor(data: Record<string, unknown> | null = null) {
        this.default = data?.default as string || ""
        this.field = data?.field as string || ""
        this.title = data?.title as string || ""
        this.type = data?.type as string || ""
        this.ifPartner = data?.ifPartner as boolean || false
        this.multiple = data?.multiple as boolean || false
        this.objToAdd = data?.objToAdd as Record<string, unknown> || {}

    }

    toData() {
        const data: Record<string, unknown> = {
            default: this.default,
            field: this.field,
            title: this.title,
            type: this.type,
            ifPartner: this.ifPartner,
            multiple: this.multiple,
            objToAdd: this.objToAdd

        }

        return data
    }
}

export class CategoryHeadlineItemDynTemplate {
    fields: CategoryHeadlineItemDynTemplateField[]
    filename: string = ""
    normalmode: boolean = false
    partnermode: boolean = false
    template: string = ""
    template_solo: string = ""
    template_partner: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        this.filename = data?.filename as string || ""
        this.normalmode = data?.normalmode as boolean || false
        this.partnermode = data?.partnermode as boolean || false
        this.template = data?.template as string || ""
        this.template_solo = data?.template_solo as string || ""
        this.template_partner = data?.template_partner as string || ""

        this.fields = []
        if (data?.fields) {
            for (const field of data?.fields as (Record<string, unknown> | null)[]) {
                if (field) {
                    const tempAnswer = new CategoryHeadlineItemDynTemplateField(field)
                    this.fields.push(tempAnswer)
                }
            }
        }
    }


    toData() {
        const data: Record<string, unknown> = {
            normalmode: this.normalmode,
            partnermode: this.partnermode,
            template: this.template,
            template_solo: this.template_solo,
            template_partner: this.template_partner,
            filename: this.filename

        }

        const fields: Record<string, unknown>[] = []
        if (this.fields) {
            for (const field of this.fields) {
                fields.push(field.toData())
            }
        }
        data.fields = fields

        return data
    }
}


export enum CategoryQuestType {
    documentUpload = "documentUpload",
    question = "question",
    schufa = "schufa"
}

export class CategoryHeadlineItem {
    order: number = -1
    title: string = ""
    questType: CategoryQuestType = CategoryQuestType.documentUpload
    toComplete: boolean = false
    rememberDaily: boolean = true
    done: boolean = false
    Files: CategoryHeadlineItemFile[]
    answer: string = ""
    type: string = ""
    answers: CategoryHeadlineItemAnswer[]
    dynTemplate: CategoryHeadlineItemDynTemplate

    schufaAnswerOne: boolean = true
    schufaAnswerTwo: boolean = true


    constructor(data: Record<string, unknown> | null = null) {
        
        this.title = data?.title as string || ""
        this.order = data?.order as number || -1
        this.questType = data?.questType as CategoryQuestType || CategoryQuestType.documentUpload
        this.toComplete = data?.toComplete as boolean || false
        this.rememberDaily = data?.rememberDaily as boolean || false
        this.done = data?.done as boolean || false
        this.answer = data?.answer as string || ""
        this.type = data?.type as string || ""
        this.dynTemplate = new CategoryHeadlineItemDynTemplate(data?.dynTemplate as Record<string, unknown>)

        this.schufaAnswerOne = data?.schufaAnswerOne as boolean
        this.schufaAnswerTwo = data?.schufaAnswerTwo as boolean


        //this.dynTemplate = data?.dynTemplate as CategoryHeadlineItemDynTemplate|| new CategoryHeadlineItemDynTemplate()

        this.Files = []
        if (data?.Files) {
            for (const file of data?.Files as (Record<string, unknown> | null)[]) {
                if (file) {
                    const tempCategoryHeadlineItemFile = new CategoryHeadlineItemFile(file)
                    this.Files.push(tempCategoryHeadlineItemFile)
                }
            }
        }

        this.answers = []
        if (data?.answers) {
            for (const answer of data?.answers as (Record<string, unknown> | null)[]) {
                if (answer) {
                    const tempAnswer = new CategoryHeadlineItemAnswer(answer)
                    this.answers.push(tempAnswer)
                }
            }
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title as string || "",
            order: this.order as number || -1,
            questType: this.questType as string || "",
            toComplete: this.toComplete as boolean || false,
            rememberDaily: this.rememberDaily,
            done: this.done as boolean || false,
            answer: this.answer as string || "",
            type: this.type as string || "",
            schufaAnswerOne: this.schufaAnswerOne as boolean || false,
            schufaAnswerTwo: this.schufaAnswerTwo as boolean || false
        }

        if(this.dynTemplate){
            data.dynTemplate = this.dynTemplate.toData()
        }

        const Files: Record<string, unknown>[] = []
        if (this.Files) {
            for (const file of this.Files) {
                Files.push(file.toData())
            }
        }
        data.Files = Files

        const answers: Record<string, unknown>[] = []
        if (this.answers) {
            for (const answer of this.answers) {
                answers.push(answer.toData())
            }
        }
        data.answers = answers

        return data
    }
}
export class CategoryHeadline {
    title: string = ""
    items: CategoryHeadlineItem[] = []
    order: number = 1

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            if (data.title) {
                this.title = data.title as string
            }
            if (data.order) {
                this.order = data.order as number
            }
        }

        this.items = []
        if (data?.items) {
            for (const item of data?.items as (Record<string, unknown> | null)[]) {
                if (item) {
                    const tempCategoryHeadlineItem = new CategoryHeadlineItem(item)
                    this.items.push(tempCategoryHeadlineItem)
                }
            }
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            title: this.title as string || "",
            order: this.order as number || -1
        }

        const items: Record<string, unknown>[] = []
        if (this.items) {
            for (const item of this.items) {
                items.push(item.toData())
            }
        }
        data.items = items

        return data
    }
}
export class Category {
    categoryId: number = -1
    headlines: CategoryHeadline[] = []
    title: string = ""
    order: number = 1

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            if (data.categoryId) {
                this.categoryId = data.categoryId as number
            }
            if (data.title) {
                this.title = data.title as string
            }
            if (data.order) {
                this.order = data.order as number
            }
        }

        this.headlines = []
        if (data?.headlines) {
            for (const headline of data?.headlines as (Record<string, unknown> | null)[]) {
                if (headline) {
                    const tempCategoryHeadline = new CategoryHeadline(headline)
                    this.headlines.push(tempCategoryHeadline)
                }
            }
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            categoryId: this.categoryId as number || -1,
            title: this.title as string || "",
            order: this.order as number || -1
        }

        data.headlines = []
        const headlines: Record<string, unknown>[] = []
        if (this.headlines) {
            for (const headline of this.headlines) {
                headlines.push(headline.toData())
            }
        }
        data.headlines = headlines
        return data
    }
}

export class CustomerPortalData extends FirestoreClass {
    private _customer: Customer
    categories: Category[] = []
    salesPartner: string = ""
    firstName: string = ""
    lastName: string = ""
    phone: string = ""
    mobile: string = ""
    email: string = ""
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""
    room: string = ""
    salutation: string = ""
    userid: string = ""
    customerid: string = ""

    constructor(customer: Customer) {
        super("customerPortalData")
        this._customer = customer
        this.customerid = customer?.id || ""
    }

    fromData(data: Record<string, unknown>): void {
        this.salesPartner = data.salesPartner as string || ""
        this.firstName = data.firstName as string || ""
        this.lastName = data.lastName as string || ""
        this.phone = data.phone as string || ""
        this.mobile = data.mobile as string || ""
        this.email = data.email as string || ""
        this.street = data.street as string || ""
        this.number = data.number as string || ""
        this.postcode = data.postcode as string || ""
        this.location = data.location as string || ""
        this.room = data.room as string || ""
        this.salutation = data.salutation as string || ""
        this.userid = data.userid as string || ""
        this.customerid = data.customerid as string || ""



        if (data.categories) {
            this.categories = []
            for (const category of data.categories as (Record<string, unknown> | null)[]) {
                if (category) {
                    const tempCategory = new Category(category)
                    this.categories.push(tempCategory)
                }
            }
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            salesPartner: this.salesPartner,
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            mobile: this.mobile,
            email: this.email,
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location,
            room: this.room,
            salutation: this.salutation,
            userid: this.userid,
            customerid: this.customerid

        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const categories: any[]  = []
        for (const category of this.categories) {
            categories.push(category.toData())
        }
        data.categories = categories

        return data
    }


    get customer() {
        return this._customer
    }

    get ref() {
        return doc(this.collectionRef, this._id)
    }
    get collectionRef() {
        return collection(this._customer.ref, "meta")
    }
}
