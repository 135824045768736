import { collection, onSnapshot, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"
import { CategoryTemplate } from "@/classes/CategoryTemplate"
import { firestore } from "@/firebase"

export interface CategoryTemplateState {
    categoryTemplates: CategoryTemplate[] | null,
    firestoreListener: {
        categoryTemplates: Unsubscribe | null
    }
}

const module: Module<CategoryTemplateState, unknown> = {
    state: {
        categoryTemplates: null,
        firestoreListener: {
            categoryTemplates: null
        }
    },
    actions: {
        attachCategoryTemplates({ state }) {
            return new Promise((resolve, reject) => {
                if (state.firestoreListener.categoryTemplates) {
                    resolve(state.categoryTemplates)
                    return
                }
                const col = collection(firestore, "categoryTemplate")
            
                state.firestoreListener.categoryTemplates = onSnapshot(col, (snapshot) => {
                    state.categoryTemplates = []
                    for (const doc of snapshot.docs) {
                        const obj = new CategoryTemplate(doc.id)
                        obj.fromData(doc.data())
                        state.categoryTemplates.push(obj)
                    }
                    resolve(state.categoryTemplates)
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        }
        
    }
}
export default module