export enum CustomApiState {
    Active = "active",
    Inactive = "inactive",
    WaitingForAuth = "waitingforauth"
}

export class CustomApi {
    useApi: boolean = false
    code: string = ""
    accessToken: string = ""
    idToken: string = ""
    partnerId: string = ""
    apiKey: string = ""
    testmode: boolean = false

    get state() {
        return {
            msg: "Aktiv",
            state: CustomApiState.Active,
            classes: ["text-success"]
        }
    }

    get scopes() {
        const scopes = [
            "partner:login:silent-sign-in",
            "report:rohdaten:lesen",
            "baufinanzierung:echtgeschaeft",
            "baufinanzierung:vorgang:lesen",
            "baufinanzierung:vorgang:schreiben",
            "baufinanzierung:angebot:ermitteln",
            "baufinanzierung:ereignis:lesen",
            "baufinanzierung:antrag:lesen",
            "privatkredit:angebot:ermitteln",
            "privatkredit:vorgang:lesen",
            "privatkredit:vorgang:schreiben",
            "dokumente:dokument:lesen",
            "dokumente:dokument:schreiben",
            "unterlagen:dokument:lesen",
            "unterlagen:dokument:schreiben",
            "openid",
            "profile"
        ]
        return scopes.join(" ")
    }

    constructor(data: Record<string, unknown> | null = null) {
        this.useApi = data?.useApi as boolean ?? false
        this.code = data?.code as string ?? ""
        this.accessToken = data?.accessToken as string ?? ""
        this.idToken = data?.idToken as string ?? ""
        this.partnerId = data?.partnerId as string ?? ""
        this.apiKey = data?.apiKey as string ?? "",
        this.testmode = data?.testmode as boolean ?? false
    }

    toData() {
        const data: Record<string, unknown> = {
            useApi: this.useApi as boolean || false,
            code: this.code as string || "",
            accessToken: this.accessToken as string || "",
            idToken: this.idToken as string || "",
            partnerId: this.partnerId as string || "",
            apiKey: this.apiKey as string || "",
            testmode: this.testmode as boolean || false
        }

        return data
    }
}
