import { collection,  doc } from "firebase/firestore"
import { Employee } from "./Employee"
import { FirestoreClass } from "./FirestoreClass"
import { Affiliate } from "./Affiliate"


export enum AffiliateLinkDisplayStyle {
    logo = "logo",
    onlyText = "onlyText"
}

export class AffiliateLink extends FirestoreClass {
    private _employee: Employee
    name: string = ""
    affiliateRouteId: string = ""
    useAffiliate: boolean = false
    affiliateId: string = ""
    affiliate?: Affiliate
    displayStyle: AffiliateLinkDisplayStyle = AffiliateLinkDisplayStyle.onlyText
    active: boolean = true
    isDefault: boolean = false

    constructor(_employee: Employee, id: string = "") {
        super(id)
        this._employee = _employee
    }

    get employee() {
        return this._employee
    }

    override fromData(data: Record<string, unknown>): void {
        if (data) {
            this.name = data.name as string ?? ""
            this.affiliateRouteId = data.affiliateRouteId as string ?? ""
            this.affiliateId = data.affiliateId as string ?? ""
            this.displayStyle = data.displayStyle as AffiliateLinkDisplayStyle ?? AffiliateLinkDisplayStyle.onlyText
            this.active = data.active as boolean ?? true
            this.isDefault = data.isDefault as boolean ?? false
            this.useAffiliate = data.useAffiliate as boolean ?? false
        }


    }
    override toData() {
        const data: Record<string, unknown> = {
            name: this.name,
            affiliateRouteId: this.affiliateRouteId,
            affiliateId: this.affiliateId,
            displayStyle: this.displayStyle,
            active: this.active,
            isDefault: this.isDefault,
            useAffiliate: this.useAffiliate
        }
        

        return data
    }

    get ref() {
        return doc(this.collectionRef, this.id)
    }

    get collectionRef() {
        return collection(this._employee.ref, "affiliateLinks")
    }
}
