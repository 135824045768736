import { createApp } from "vue"
import app from "./app.vue"
import router from "./router"
import store from "./store"
import "./index.css"

import "@/firebase"

// bodyParser.json({ limit: "50mb" })



createApp(app).use(store).use(router).mount("#app")

