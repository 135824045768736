import { Log } from "@/classes/Log"
import { Snackbar } from "@/interfaces/SnackbarInterface"
import { Module } from "vuex"
import store from ".."

export interface AppState {
    theme: "florian-thoms" | "business-luchs" | "custom",
    sidebarVisible: boolean,
    snackbar: {
        visible: boolean,
        color: string,
        message: string
    }
}

const module: Module<AppState, unknown> = {
    state: {
        theme: "business-luchs",
        sidebarVisible: false,
        snackbar: {
            visible: false,
            color: "default",
            message: ""
        }
    },
    mutations: {
        snackbar(state, { message, color }: Snackbar) {
            state.snackbar.message = message
            if (color) {
                state.snackbar.color = color
            } else {
                state.snackbar.color = "default"
            }
            state.snackbar.visible = true
        },
        async makeLogEntry(state, { docRefPath, frontend, newData, oldData, logType }) {
            try{
                const log = new Log()
                log.username = `${store.state.auth?.profile?.firstName} ${store.state.auth?.profile?.lastName}` ?? "unbekannt"
                log.userid = store.state.auth?.profile?.sub ?? "unbekannt"
                log.companyid = store.state.company?.company?.id ?? "unbekannt"
                log.docRefPath = docRefPath
                log.frontend = frontend
                log.newData = newData
                log.oldData = oldData
                log.logType = logType
                
                await log.save()
            }
            catch(err) {
                console.log(err)
            }
        }
    }
}
export default module