import { Company } from "@/classes/Company"
import { Employee } from "@/classes/Employee"
import { EmployeePermission } from "@/classes/EmployeePermission"
import { Module } from "vuex"

export interface AuthState {
    isSignedIn: boolean
    isAuthenticating: boolean,
    accessToken: string,
    permission: EmployeePermission | null,
    subsIWorkFor: string[],
    profile: {
        sub: string
        firstName: string
        lastName: string
        email: string
        gender: "male" | "female"
        company: string | null
        picture: string | null
        phone: string | null
    } | null
}

const module: Module<AuthState, unknown> = {
    state: {
        isSignedIn: false,
        isAuthenticating: true,
        accessToken: "",
        permission: null,
        subsIWorkFor: [],
        profile: null
    },
    mutations: {
        setAuthClaims(state, { user, claims }) {
            if (claims) {
                const permissions = new EmployeePermission(new Employee(new Company(""), ""))
                permissions.permissions = claims.permissions
                
                state.accessToken = user.accessToken
                state.permission = permissions
                state.subsIWorkFor = claims.subsIWorkFor
               
                state.profile = {
                    sub: user.uid,
                    firstName: claims.firstName as string,
                    lastName: claims.lastName as string,
                    email: claims.auth_email as string,
                    gender: claims.gender as "male" | "female",
                    phone: claims.phone as string | null,
                    picture: claims.picture as string | null,
                    company: claims.company as string | null
                }
            } else {
                state.accessToken = ""
                state.profile = null
            }
        }
    }
}
export default module