import { onSnapshot, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"
import { AffiliateRoute } from "@/classes/AffiliateRoute"
import { Employee } from "@/classes/Employee"

export interface AffiliateRoutesState {
    affiliateRoutes: Map<string, AffiliateRoute[]>,
    firestoreListener: {
        affiliateRoutes: Unsubscribe | null
    }
}

const module: Module<AffiliateRoutesState, unknown> = {
    state: {
        affiliateRoutes: new Map<string, AffiliateRoute[]>(),
        firestoreListener: {
            affiliateRoutes: null
        }
    },
    actions: {
        getEmployeeAffiliateRoutes({ state }, employee: Employee) {

            const createAffiliateRoute = async (employee: Employee, id: string, name: string) => {
                const affiliateRoute = new AffiliateRoute(employee, id)
                affiliateRoute.fromData({
                    "isDefault": true,
                    "name": name,
                    "duration": "In 3 Minuten zu Ihren persönlichen Finanzierungsangeboten",
                    "pages": [
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Kauf Neubau",
                                    "title": "Kauf Neubau",
                                    "type": "button",
                                    "uniqueId": "0cef6073-0651-4c0c-9753-0964ca7382d6",
                                    "order": 0
                                },
                                {
                                    "name": "Kauf Bestandsimmobilie",
                                    "title": "Kauf Bestandsimmobilie",
                                    "type": "button",
                                    "uniqueId": "ee5e332d-c76d-44bb-8152-74e0e6d40cd8",
                                    "order": 0
                                },
                                {
                                    "name": "Eigenes Bauvorhaben",
                                    "title": "Eigenes Bauvorhaben",
                                    "type": "button",
                                    "uniqueId": "706b193f-5faf-4602-9f57-882e886c3f0a",
                                    "order": 0
                                },
                                {
                                    "name": "Modernisierung",
                                    "title": "Modernisierung",
                                    "type": "button",
                                    "uniqueId": "5fe2fb92-cb4e-45c9-9e5e-c7be6553f4f3",
                                    "order": 0
                                },
                                {
                                    "name": "Anschlussfinanzierung",
                                    "title": "Anschlussfinanzierung",
                                    "type": "button",
                                    "uniqueId": "70e44ba6-0cf4-40a2-8bb8-0a1efbd07428",
                                    "order": 0
                                },
                                {
                                    "name": "Kapitalbeschaffung",
                                    "title": "Kapitalbeschaffung",
                                    "type": "button",
                                    "uniqueId": "ac5c9f55-aeda-40b3-9365-6bcb86df765f",
                                    "order": 0
                                }
                            ],
                            "name": "Was möchten Sie finanzieren?",
                            "conditions": [],
                            "title": "Was möchten Sie finanzieren?",
                            "type": "buttons",
                            "hasCondition": false,
                            "uniqueId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                            "order": 1
                        },
                        {
                            "subTitle": "",
                            "elements": [],
                            "name": "Haben Sie bereits eine Immobilie gefunden? ",
                            "title": "Haben Sie bereits eine Immobilie gefunden? ",
                            "type": "yesno",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "0cef6073-0651-4c0c-9753-0964ca7382d6",
                                    "uniqueId": "adc7e7e5-7a14-4416-b946-bc352e32b3b0",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "ee5e332d-c76d-44bb-8152-74e0e6d40cd8",
                                    "uniqueId": "88efe30b-6d13-4b8f-b7c8-25181c03b592",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "ec7fa272-8496-4c6d-bd8c-166b40982e10",
                            "order": 2
                        },
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Selbst bewohnen",
                                    "title": "Selbst bewohnen",
                                    "type": "button",
                                    "uniqueId": "97d0337f-057c-4e4b-8c4c-096e9e6de321",
                                    "order": 0
                                },
                                {
                                    "name": "Vermieten",
                                    "title": "Vermieten",
                                    "type": "button",
                                    "uniqueId": "f7835d7e-4042-40d9-8cb5-d9ff6fadcf2a",
                                    "order": 0
                                },
                                {
                                    "name": "Teilweise Vermieten",
                                    "title": "Teilweise Vermieten",
                                    "type": "button",
                                    "uniqueId": "56d297ac-5c75-435c-969f-ea7f36fc9680",
                                    "order": 0
                                }
                            ],
                            "name": "Wie möchten Sie die Immobilie nutzen?",
                            "title": "Wie möchten Sie die Immobilie nutzen?",
                            "type": "buttons",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "0cef6073-0651-4c0c-9753-0964ca7382d6",
                                    "uniqueId": "a69e3ec5-1b57-410b-8cbe-85b251ece2f8",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "ee5e332d-c76d-44bb-8152-74e0e6d40cd8",
                                    "uniqueId": "9b4c361a-8eab-4ce8-86e3-b5d85275bf07",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "706b193f-5faf-4602-9f57-882e886c3f0a",
                                    "uniqueId": "4f33ac78-8376-40d2-960b-c5e0bb63002e",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "662783a6-6d33-45ae-b56b-95228526e007",
                            "order": 3
                        },
                        {
                            "subTitle": "Sollten Sie sich noch nicht sicher sein, geben Sie bitte eine PLZ aus dem Bundesland ein, in dem Sie die Immobilie oder das Grundstück erwerben möchten, da die Kaufnebenkosten zwischen den Bundesländern variieren können.",
                            "elements": [
                                {
                                    "name": "PLZ",
                                    "title": "PLZ",
                                    "type": "numberfield",
                                    "uniqueId": "3e36840f-722e-475f-912d-7df4abfe2f2d",
                                    "order": 0
                                },
                                {
                                    "name": "Stadt/Ort",
                                    "title": "Stadt/Ort",
                                    "type": "textfield",
                                    "uniqueId": "a7799399-274c-48b5-9f70-6e90fd6acf75",
                                    "order": 0
                                }
                            ],
                            "name": "In welcher Stadt ist die Immobilie?",
                            "conditions": [],
                            "title": "In welcher Stadt ist die Immobilie?",
                            "type": "formular",
                            "hasCondition": false,
                            "uniqueId": "19d3ce6b-e337-44fc-88c8-6fac595fb6e8",
                            "order": 4
                        },
                        {
                            "subTitle": "Wenn Sie noch keinen konkreten Kaufpreis kennen, tragen Sie bitte hier Ihr maximales Budget ein (nur der Preis der Immobilie ohne Nebenkosten).",
                            "elements": [
                                {
                                    "name": "Kaufpreis",
                                    "title": "Kaufpreis",
                                    "type": "euro",
                                    "uniqueId": "25ef949e-7c31-45c8-9f71-878b64b941c1",
                                    "order": 0
                                }
                            ],
                            "name": "Wie hoch ist der Kaufpreis?",
                            "title": "Wie hoch ist der Kaufpreis?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "0cef6073-0651-4c0c-9753-0964ca7382d6",
                                    "uniqueId": "e797542a-95cb-4798-a49f-ddc93d4c76c7",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "ee5e332d-c76d-44bb-8152-74e0e6d40cd8",
                                    "uniqueId": "8afb184d-e61c-4d36-830b-fb22e8182726",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "b5905ad5-9cfd-4707-bb69-0c15993f163c",
                            "order": 5
                        },
                        {
                            "subTitle": "",
                            "elements": [],
                            "name": "Besitzen Sie das Grundstück bereits?",
                            "title": "Besitzen Sie das Grundstück bereits?",
                            "type": "yesno",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "706b193f-5faf-4602-9f57-882e886c3f0a",
                                    "uniqueId": "7ea342e5-a035-4d56-9324-2274e1166058",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "7dbc661b-563d-43a7-b797-26ccae4e4e6f",
                            "order": 0
                        },
                        {
                            "subTitle": "Sollten Sie das Grundstück in den letzten fünf Jahren erworben haben, geben Sie bitte den ursprünglichen Kaufpreis an.",
                            "elements": [
                                {
                                    "name": "Wert des Grundstücks",
                                    "title": "Wert des Grundstücks",
                                    "type": "euro",
                                    "uniqueId": "2720b4fc-7429-4a20-9093-e33986b2554c",
                                    "order": 0
                                }
                            ],
                            "name": "Wie viel ist das Grundstück wert?",
                            "title": "Wie viel ist das Grundstück wert?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "7dbc661b-563d-43a7-b797-26ccae4e4e6f",
                                    "value": "__YESBUTTON__",
                                    "uniqueId": "d8d64b35-584a-4d6a-aabf-08e8cbab07d2",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "33def312-9589-4cdd-a158-be0656fc878d",
                            "order": 0
                        },
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Kaufpreis des Grundstücks",
                                    "title": "Kaufpreis des Grundstücks",
                                    "type": "euro",
                                    "uniqueId": "197b7eb9-49fd-4038-a73d-24fa25cb3f23",
                                    "order": 0
                                }
                            ],
                            "name": "Wie hoch ist der Kaufpreis des Grundstücks?",
                            "title": "Wie hoch ist der Kaufpreis des Grundstücks?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "7dbc661b-563d-43a7-b797-26ccae4e4e6f",
                                    "value": "__NOBUTTON__",
                                    "uniqueId": "771bf083-2de6-467f-9a2c-9bcc835eddea",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "e8077fa6-9d9f-49ca-8ce1-28d68919e573",
                            "order": 0
                        },
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Höhe der Baukosten",
                                    "title": "Höhe der Baukosten",
                                    "type": "euro",
                                    "uniqueId": "613d6173-48f1-4e0b-a962-7481538ae196",
                                    "order": 0
                                }
                            ],
                            "name": "Wie hoch sind die geschätzten Baukosten?",
                            "title": "Wie hoch sind die geschätzten Baukosten?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "706b193f-5faf-4602-9f57-882e886c3f0a",
                                    "uniqueId": "37e39cdc-c9cd-4cf2-91a5-f2031f9dcacc",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "7f02f465-c1a0-4bf5-9e77-75eadff2acde",
                            "order": 0
                        },
                        {
                            "subTitle": "Falls Sie sich noch nicht entschieden haben, geben Sie bitte den maximalen Betrag an, den Sie einbringen könnten.",
                            "elements": [
                                {
                                    "name": "Eigenkapital",
                                    "title": "Eigenkapital",
                                    "type": "euro",
                                    "uniqueId": "ef8a22f1-4b17-4d30-b8e7-cd217675a605",
                                    "order": 0
                                }
                            ],
                            "name": "Wie viel Eigenkapital möchten Sie für die Finanzierung einsetzen?",
                            "title": "Wie viel Eigenkapital möchten Sie für die Finanzierung einsetzen?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "0cef6073-0651-4c0c-9753-0964ca7382d6",
                                    "uniqueId": "d775c0ab-4e19-4a29-97d4-2d69820e7683",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "ee5e332d-c76d-44bb-8152-74e0e6d40cd8",
                                    "uniqueId": "85982752-738a-4491-917b-26172a4d68f8",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "706b193f-5faf-4602-9f57-882e886c3f0a",
                                    "uniqueId": "72428fa5-e42c-4dee-9b57-ffde2a2ff681",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "946bfb1c-83e4-4b6d-8271-e998e697a53f",
                            "order": 0
                        },
                        {
                            "subTitle": "Die Provision für den Immobilienmakler wird vom Käufer bezahlt und kann je nach Bundesland und Anbieter variieren.",
                            "elements": [
                                {
                                    "name": "Maklergebühr",
                                    "title": "Maklergebühr",
                                    "type": "button",
                                    "uniqueId": "84be719b-57f1-4e0f-9fda-843a3f445915",
                                    "order": 0
                                },
                                {
                                    "name": "Provisionsfrei",
                                    "title": "Provisionsfrei",
                                    "type": "button",
                                    "uniqueId": "7c6a6478-f91d-4c58-8b59-a6e927826e5f",
                                    "order": 0
                                },
                                {
                                    "name": "Weiß nicht",
                                    "title": "Weiß nicht",
                                    "type": "button",
                                    "uniqueId": "be43b084-6c64-4ef6-9a2e-10640297fdfe",
                                    "order": 0
                                }
                            ],
                            "name": "Fällt eine Maklergebühr an?",
                            "title": "Fällt eine Maklergebühr an?",
                            "type": "buttons",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "0cef6073-0651-4c0c-9753-0964ca7382d6",
                                    "uniqueId": "ec79fc50-9573-405a-84ee-dcd9990ed517",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "ee5e332d-c76d-44bb-8152-74e0e6d40cd8",
                                    "uniqueId": "36f326f6-b129-4cf5-8e37-7cb981990a3f",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "6a4c46e3-3663-4a01-8491-8059d3d5d3b1",
                            "order": 0
                        },
                        {
                            "subTitle": "Die Provision für den Immobilienmakler wird vom Käufer bezahlt und kann je nach Bundesland und Anbieter variieren.",
                            "elements": [
                                {
                                    "name": "Maklergebühren",
                                    "title": "Maklergebühren",
                                    "type": "euro",
                                    "uniqueId": "35b1986d-f814-4a11-a05c-0f55217e5af6",
                                    "order": 0
                                }
                            ],
                            "name": "Fällt eine Maklergebühr an?",
                            "title": "Fällt eine Maklergebühr an?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "6a4c46e3-3663-4a01-8491-8059d3d5d3b1",
                                    "value": "84be719b-57f1-4e0f-9fda-843a3f445915",
                                    "uniqueId": "3c356118-810c-4121-a4a3-6fcebb48fca6",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "450de673-0083-45fd-9219-e75da35f9297",
                            "order": 0
                        },
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Aktueller Wert",
                                    "title": "Aktueller Wert",
                                    "type": "euro",
                                    "uniqueId": "dc2eaf35-d016-4baf-8ce0-fd065d89528d",
                                    "order": 0
                                }
                            ],
                            "name": "Was ist der aktuelle Wert der Immobilie?",
                            "title": "Was ist der aktuelle Wert der Immobilie?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "ac5c9f55-aeda-40b3-9365-6bcb86df765f",
                                    "uniqueId": "9ac122a7-b8fa-40da-b9dc-9e3da91265aa",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "70e44ba6-0cf4-40a2-8bb8-0a1efbd07428",
                                    "uniqueId": "7f71c32a-af3a-4fdb-9272-6714816890e8",
                                    "operator": "==",
                                    "order": 0
                                },
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "5fe2fb92-cb4e-45c9-9e5e-c7be6553f4f3",
                                    "uniqueId": "dbe66b26-d108-4d81-938e-3e06a2330025",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "444b1d51-3930-48c5-ad16-9966a015117f",
                            "order": 0
                        },
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Modernisierungskosten",
                                    "title": "Modernisierungskosten",
                                    "type": "euro",
                                    "uniqueId": "cdfc0b66-367c-4d7f-be15-c51a9190042b",
                                    "order": 0
                                }
                            ],
                            "name": "Wie hoch sind die Modernisierungskosten?",
                            "title": "Wie hoch sind die Modernisierungskosten?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "5fe2fb92-cb4e-45c9-9e5e-c7be6553f4f3",
                                    "uniqueId": "a12d5f1c-7ee6-4212-9022-48dd29ca7e4d",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "cb1d442f-62e4-4867-9ec0-7928143dd6ae",
                            "order": 0
                        },
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Gesamtbedarf Anschlussfinanzierung",
                                    "title": "Gesamtbedarf Anschlussfinanzierung",
                                    "type": "euro",
                                    "uniqueId": "be06238e-7357-4ad3-b133-dd2b1e46983c",
                                    "order": 0
                                }
                            ],
                            "name": "Wie hoch ist der Gesamtbedarf für die Anschlussfinanzierung?",
                            "title": "Wie hoch ist der Gesamtbedarf für die Anschlussfinanzierung?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "70e44ba6-0cf4-40a2-8bb8-0a1efbd07428",
                                    "uniqueId": "007a5382-00fa-4a44-858e-c4a27615c894",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "7869ae26-bae3-4d8c-991a-b86f8e22a56c",
                            "order": 0
                        },
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Kapital Aufnahmewert",
                                    "title": "Kapital Aufnahmewert",
                                    "type": "euro",
                                    "uniqueId": "97470775-af45-463b-98d0-6287ea563778",
                                    "order": 0
                                }
                            ],
                            "name": "Wie viel Kapital wollen Sie aufnehmen?",
                            "title": "Wie viel Kapital wollen Sie aufnehmen?",
                            "type": "formular",
                            "conditions": [
                                {
                                    "elementId": "",
                                    "pageId": "040bb964-828f-4c75-a0dd-601ea5ffa8fd",
                                    "value": "ac5c9f55-aeda-40b3-9365-6bcb86df765f",
                                    "uniqueId": "ed642e8b-f134-4d86-b227-7a298cef3b6e",
                                    "operator": "==",
                                    "order": 0
                                }
                            ],
                            "hasCondition": false,
                            "uniqueId": "9d10733d-dddc-4034-93f4-fd457dd207b4",
                            "order": 0
                        },
                        {
                            "subTitle": "Falls Sie verheiratet sind, stellen Sie den Antrag bitte gemeinsam mit Ihrem Ehepartner.",
                            "elements": [
                                {
                                    "name": "Alleine",
                                    "title": "Alleine",
                                    "type": "button",
                                    "uniqueId": "4236c559-5f37-4253-a605-90c624845252",
                                    "order": 0
                                },
                                {
                                    "name": "Mit einer weiteren Person",
                                    "title": "Mit einer weiteren Person",
                                    "type": "button",
                                    "uniqueId": "fccb986f-1863-466e-9585-e51db3efe357",
                                    "order": 0
                                }
                            ],
                            "name": "Möchten Sie alleine oder mit einer weiteren Person finanzieren?",
                            "conditions": [],
                            "title": "Möchten Sie alleine oder mit einer weiteren Person finanzieren?",
                            "type": "buttons",
                            "hasCondition": false,
                            "uniqueId": "f68f51db-3891-46ba-9084-5410954a2ada",
                            "order": 0
                        },
                        {
                            "subTitle": "",
                            "elements": [
                                {
                                    "name": "Angestellt",
                                    "title": "Angestellt",
                                    "type": "button",
                                    "uniqueId": "aaed8f43-77b1-4046-a7de-0058704c2b36",
                                    "order": 1
                                },
                                {
                                    "name": "Selbstständig",
                                    "title": "Selbstständig",
                                    "type": "button",
                                    "uniqueId": "6739e344-7798-4148-9c72-e85384e4f187",
                                    "order": 2
                                },
                                {
                                    "name": "Selbständige/r Handwerker/in",
                                    "title": "Selbständige/r Handwerker/in",
                                    "type": "button",
                                    "uniqueId": "93a414c9-c600-4ec1-9242-0620990db280",
                                    "order": 3
                                },
                                {
                                    "name": "Arbeiter/in",
                                    "title": "Arbeiter/in",
                                    "type": "button",
                                    "uniqueId": "36d43ca0-6db5-45a5-a144-82b3500df5b7",
                                    "order": 4
                                },
                                {
                                    "name": "Freiberufler/in",
                                    "title": "Freiberufler/in",
                                    "type": "button",
                                    "uniqueId": "e90cb2e5-da0d-465f-84e2-5d3e8e1c457e",
                                    "order": 5
                                },
                                {
                                    "name": "Beamter/in",
                                    "title": "Beamter/in",
                                    "type": "button",
                                    "uniqueId": "3cd9c125-0f36-4540-94da-ed74acb1d9f6",
                                    "order": 6
                                },
                                {
                                    "name": "Privatier/Privatière",
                                    "title": "Privatier/Privatière",
                                    "type": "button",
                                    "uniqueId": "528c598a-f0b3-4b50-b1c9-4133c46f50c4",
                                    "order": 7
                                },
                                {
                                    "name": "Rentner/in",
                                    "title": "Rentner/in",
                                    "type": "button",
                                    "uniqueId": "3144d587-83e1-4782-b4ed-e3c44382ce8e",
                                    "order": 8
                                },
                                {
                                    "name": "Anderes",
                                    "title": "Anderes",
                                    "type": "button",
                                    "uniqueId": "cf1f0f09-f7b2-4213-bf3b-d9835f062083",
                                    "order": 9
                                }
                            ],
                            "name": "In welchem Arbeitsverhältnis sind Sie beschäftigt?",
                            "conditions": [],
                            "title": "In welchem Arbeitsverhältnis sind Sie beschäftigt?",
                            "type": "buttons",
                            "hasCondition": false,
                            "uniqueId": "94cafe13-8f67-4a16-8bc0-ede4df56b514",
                            "order": 0
                        },
                        {
                            "subTitle": "Bitte geben Sie hier Ihr gesamtes Nettoeinkommen, einschließlich Ihres Gehaltes sowie vertraglich zugesicherter Bonuszahlungen und weitere Einkommen, an.",
                            "elements": [
                                {
                                    "name": "Nettoeinkommen",
                                    "title": "Nettoeinkommen",
                                    "type": "euro",
                                    "uniqueId": "38c559d3-7b38-49c7-b855-aec082a31202",
                                    "order": 0
                                }
                            ],
                            "name": "Wie hoch ist das gesamte monatliche Nettoeinkommen Ihres Haushalts?",
                            "conditions": [],
                            "title": "Wie hoch ist das gesamte monatliche Nettoeinkommen Ihres Haushalts?",
                            "type": "formular",
                            "hasCondition": false,
                            "uniqueId": "47df0ef2-7826-4e2c-80ce-9650066d4010",
                            "order": 0
                        }
                    ]
                })

                await affiliateRoute.set()
                return affiliateRoute
            }

            const createDefaultAffiliateRoutes = async (employee: Employee) => {
                await createAffiliateRoute(employee, "standard_baufinanzierung", "Baufinanzierung Standard")
            }

            return new Promise((resolve, reject) => {
                if (state.affiliateRoutes?.get(employee.id)) {
                    resolve(state.affiliateRoutes?.get(employee.id))
                    return
                }

                const affiliateRoute = new AffiliateRoute(employee)
                onSnapshot(affiliateRoute.collectionRef, async (snapshot) => {
                    const _affiliateRoutes: AffiliateRoute[] = []
                    for (const doc of snapshot.docs) {
                        const obj = new AffiliateRoute(employee, doc.id)
                        obj.fromData(doc.data())
                        _affiliateRoutes.push(obj)
                    }

                    if (_affiliateRoutes.length === 0) {
                        createDefaultAffiliateRoutes(employee)
                    }

                    state.affiliateRoutes?.set(employee.id, _affiliateRoutes)
                    resolve(state.affiliateRoutes?.get(employee.id))
                }, (err) => {
                    console.error(err)
                    reject(err)
                })
            })
        }

    }
}
export default module