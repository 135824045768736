import { CustomApi, CustomApiState } from "./CustomApi"

export class ThinkimmoApi extends CustomApi {
    id: string = ""
    override get state() {
        if (!this.useApi) {
            return {
                msg: "Inaktiv",
                state: CustomApiState.Inactive,
                classes: ["text-black"]
            }
        }

        if (!this.apiKey) {
            return {
                msg: "Kein API Key angegeben",
                state: CustomApiState.WaitingForAuth,
                classes: ["text-yellow-500"]
            }
        }

        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
        
        if (!uuidRegex.test(this.apiKey)) {
            return {
                msg: "Kein valider API Key angegeben",
                state: CustomApiState.WaitingForAuth,
                classes: ["text-yellow-500"]
            }
        }

        return {
            msg: "Aktiv",
            state: CustomApiState.Active,
            classes: ["text-success"]
        }
    }
}
