import { AffiliateLinkOld } from "@/classes/AffiliateLinkOld"
import { doc, getDoc, onSnapshot, query, Unsubscribe, where } from "firebase/firestore"
import { Module } from "vuex"
import { firestore } from "@/firebase"
import { collection } from "firebase/firestore"
import store from ".."
import { PublicEmployee } from "@/classes/PublicEmployee"

export interface AffiliateLinkOldState {
    isLoading: boolean
    affiliateLinks: AffiliateLinkOld[]
    firestoreListener: {
        affiliateLinks: Unsubscribe | null
    }
}

const module: Module<AffiliateLinkOldState, unknown> = {
    state: {
        isLoading: true,
        affiliateLinks: [],
        firestoreListener: {
            affiliateLinks: null
        }
    },
    actions: {
        attachAffiliateLinkOlds({ state }) {
            return new Promise(async (resolve, reject) => {
                if (state.firestoreListener.affiliateLinks) {
                    state.isLoading = false
                    resolve(state.affiliateLinks)
                    return
                }

                state.isLoading = true
                const ref = query(
                    collection(firestore, "affiliateLink"),
                    where("merchant.user", "==", store.state.auth!.profile!.sub),
                    where("merchant.company", "==", store.state.company!.company!.id)
                )
                state.firestoreListener.affiliateLinks = onSnapshot(ref, (snapshot) => {
                    state.affiliateLinks = []
                    for (const doc of snapshot.docs) {
                        const affiliateLink = new AffiliateLinkOld(doc.id)
                        affiliateLink.fromData(doc.data())
                        state.affiliateLinks.push(affiliateLink)
                    }
                    state.isLoading = false
                    resolve(state.affiliateLinks)
                }, (err) => {
                    reject(err)
                })
            })
        },
        loadAffiliateLinkOldById({  }, id: string) {
            return new Promise(async (resolve, reject) => {
                try{
                    const ref = doc(firestore, "affiliateLink", id)
                    const affiliateLinkSnapshot = await getDoc(ref)
                    const affiliateLinkData = affiliateLinkSnapshot.data()
                    if(affiliateLinkData) {
                        const affiliateLink = new AffiliateLinkOld(affiliateLinkSnapshot.id)
                        affiliateLink.fromData(affiliateLinkData)
                        resolve(affiliateLink)
                    }
                }
                catch(err) {
                    reject(err)
                }

            })
        },
        loadAffiliateLinkOldsFromPublicEmployee({ state }, publicEmployee: PublicEmployee) {

            return new Promise(async (resolve, reject) => {
                const ref = query(
                    collection(firestore, "affiliateLink"),
                    where("merchant.user", "==", publicEmployee.id),
                    where("merchant.company", "==", publicEmployee.company.id)
                )
                const affiliateLinks: AffiliateLinkOld[] = []
                state.firestoreListener.affiliateLinks = onSnapshot(ref, (snapshot) => {
                    for (const doc of snapshot.docs) {
                        const affiliateLink = new AffiliateLinkOld(doc.id)
                        affiliateLink.fromData(doc.data())
                        affiliateLinks.push(affiliateLink)
                    }
                    resolve(affiliateLinks)
                }, (err) => {
                    reject(err)
                })
            })
        }
    }
}
export default module
