import { collection, doc } from "firebase/firestore"
import { Employee } from "./Employee"
import { FirestoreClass } from "./FirestoreClass"
import { uuid } from "vue-uuid"


export enum ProductFieldType {
    text = "text",
    number = "number",
    date = "date",
    percent = "percent",
    euro = "euro"
}

export class ProductField {
    name: string = ""
    title: string = ""
    type: ProductFieldType = ProductFieldType.text
    order: string = "1"
    uniqueId: string
    showInOverview: string = "0"

    get orderAsNumber() {
        const ret = parseInt(this.order)
        return ret
    }

    constructor(data: Record<string, unknown> | null = null) {

        this.name = data?.name as string ?? ""
        this.title = data?.title as string ?? ""
        this.type = data?.type as ProductFieldType ?? ProductFieldType.text
        this.order = data?.order as string ?? "1"
        this.uniqueId = data?.uniqueId as string ?? uuid.v4()

        this.showInOverview = data?.showInOverview as string ?? "0"

    }

    toData() {
        const data: Record<string, unknown> = {
            name: this.name as string || "",
            title: this.title as string || "",
            type: this.type as ProductFieldType || ProductFieldType.text,
            order: this.order as string || "1",
            uniqueId: this.uniqueId as string || uuid.v4(),
            showInOverview: this.showInOverview as string || "0"
        }
        return data
    }
}

export class Product extends FirestoreClass {
    private _employee: Employee
    name: string = ""
    category: string = ""
    subCategory: string = ""
    isDefault: boolean = false
    fields: ProductField[] = []

    get fieldsSorted() {
        const fields = [...this.fields]
        fields.sort((a, b) => a.orderAsNumber - b.orderAsNumber)


        return fields
    }

    get highestFieldSort() {
        const arr: number[] = this.fields.map(f => f.orderAsNumber)
        let max = Math.max.apply(null, arr)

        if (max.toString() === "-Infinity") {
            max = 0
        }

        return max
    }

    constructor(_employee: Employee, id: string = "") {
        super(id)
        this._employee = _employee
    }

    get overviewFields() {

        return this.fields.filter(f => f.showInOverview === "1") ?? []

    }

    get employee() {
        return this._employee
    }

    override fromData(data: Record<string, unknown>): void {
        if (data) {
            this.name = data.name as string
            this.category = data.category as string
            this.subCategory = data.subCategory as string
            this.isDefault = data.isDefault as boolean || false

            if (data.fields) {
                this.fields = []
                for (const fieldData of data.fields as (Record<string, unknown> | null)[]) {
                    const tempField = new ProductField(fieldData)
                    this.fields.push(tempField)
                }
            }
        }
    }
    override toData() {
        const data: Record<string, unknown> = {}
        data.name = this.name
        data.category = this.category
        data.subCategory = this.subCategory
        data.isDefault = this.isDefault || false

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fields: any[] = []
        for (const field of this.fields) {
            fields.push(field.toData())
        }
        data.fields = fields

        return data
    }

    get ref() {
        return doc(this.collectionRef, this.id)
    }

    get collectionRef() {
        return collection(this._employee.ref, "products")
    }
}
