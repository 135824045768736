import { Customer } from "@/classes/Customer"
import { CustomerPortalData } from "@/classes/CustomerPortalData"
import { Module } from "vuex"

export interface CustomerPortalDataState {
    isLoading: boolean
    customerPortalData: CustomerPortalData | null
    firestoreListener: {
        customerPortalData: CustomerPortalData | null
    }
}

const module: Module<CustomerPortalDataState, unknown> = {
    state: {
        isLoading: false,
        customerPortalData: null,
        firestoreListener: {
            customerPortalData: null
        }
    },
    actions: {
        getCustomerPortalData({ state }, customer: Customer) {
            return new Promise(async (resolve, reject) => {
                if (state.firestoreListener.customerPortalData) {
                    resolve(state.customerPortalData)
                    return
                }
               
                const customerPortalData = new CustomerPortalData(customer)

                if (await customerPortalData.load()) {
                    state.customerPortalData = customerPortalData
                    resolve(customerPortalData)
                    return
                } else {
                    await customerPortalData.set()
                    return customerPortalData
                }
                reject(new Error("not_found"))
            })
        }
        
    }
}
export default module