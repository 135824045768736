import { firestore } from "@/firebase"
import { collection, doc } from "firebase/firestore"
import { FirestoreClass } from "./FirestoreClass"
export interface CompanyUser {
    type: string[]
}
export class Address {
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""
    room: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.street = data.street as string
            this.number = data.number as string
            this.postcode = data.postcode as string
            this.location = data.location as string
            this.room = data.room as string

        }
    }
    toData() {
        return {
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location,
            room: this.room

        }
    }
}
export interface CompanyColor {
    primary: { r: number, g: number, b: number }
    primaryHover: { r: number, g: number, b: number }
    border: { r: number, g: number, b: number }
    card: { r: number, g: number, b: number }
    background: { r: number, g: number, b: number }
}
export class Company extends FirestoreClass {
    name: string = ""
    addresses: Address[] = []
    logo: string = ""
    colors: CompanyColor | null = null
    activeEmployees: string[] = []
    inactiveEmployees: string[] = []
    type: string = ""
    calcSecondaryColors: boolean = true

    constructor(id: string = "") {
        super(id)
    }

    addAddress(address: Address) {
        const index = this.addresses.findIndex((a) => {
            if (a.location != address.location) {
                return false
            }
            if (a.street != address.street) {
                return false
            }
            if (a.number != address.number) {
                return false
            }
            if (a.postcode != address.postcode) {
                return false
            }

            return true
        })
        if (index == -1) {
            this.addresses.push(address)
        }
    }
    deleteAddress(address: Address) {
        const index = this.addresses.findIndex((a) => {
            if (a.location != address.location) {
                return false
            }
            if (a.street != address.street) {
                return false
            }
            if (a.number != address.number) {
                return false
            }
            if (a.postcode != address.postcode) {
                return false
            }

            return true
        })
        if (index != -1) {
            this.addresses.splice(index, 1)
        }
    }

    override fromData(data: Record<string, unknown>) {
        this.name = data.name as string || ""
        this.addresses = [] || []
        this.logo = data.logo as string || ""
        this.colors = data.colors as CompanyColor | null || null
        this.activeEmployees = data.activeEmployees as string[] || []
        this.inactiveEmployees = data.inactiveEmployees as string[] || []

        this.calcSecondaryColors = true
        if(data.calcSecondaryColors !== undefined) {
            this.calcSecondaryColors = data.calcSecondaryColors as boolean
        }
        
        if (data.addresses) {
            for (const address of data.addresses as Record<string, unknown>[]) {
                this.addresses.push(new Address(address))
            }
        }
        this.type = data.type as string
    }
    override toData() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const addresses: any[]  = []
        for (const address of this.addresses) {
            addresses.push(address.toData())
        }
        const data: Record<string, unknown> = {
            name: this.name,
            addresses: addresses,
            activeEmployees: this.activeEmployees,
            inactiveEmployees: this.inactiveEmployees,
            calcSecondaryColors: this.calcSecondaryColors
        }
        if (this.logo) {
            data.logo = this.logo
        }
        if (this.colors) {
            data.colors = this.colors
        }

        return data
    }

    override get ref() {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef() {
        return collection(firestore, "company")
    }
}